<template>
  <div class="front-page">test</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export const FrontPage = defineComponent({
  setup() {
    return {
      //
    };
  },
});
export default FrontPage;
</script>
<style lang="scss" scoped>
.front-page {
}
</style>
