
import { defineComponent } from "vue";

export const FrontPage = defineComponent({
  setup() {
    return {
      //
    };
  },
});
export default FrontPage;
